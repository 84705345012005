




import { Vue, Component } from 'vue-property-decorator'
import { ElLoadingComponent } from 'element-ui/types/loading'
import QsUtil from '@/utils/qs'
import Routes from '@/constant/routes'
import UserService from '@/services/user'
import { getWecomAuthRedirect, setWecomAuthRedirect } from '@/utils/cookies'

@Component({
  name: 'AuthorizeCallback'
})
export default class extends Vue {
  private loading: ElLoadingComponent | undefined

  async created() {
    this.loading = this.$loading({
      lock: true,
      text: 'Loading'
    })
    const code = QsUtil.getUrlKey('code')
    if (!code) {
      await this.$router.replace(Routes.Login)
      return
    }
    await UserService.getTokenByCode(code,
      async() => {
        await this.$router.replace(Routes.Login)
      },
      async() => {
        await this.$router.replace(getWecomAuthRedirect() ?? Routes.Home)
        setWecomAuthRedirect('')
      })
  }

  destroyed() {
    if (this.loading) this.loading.close()
  }
}
